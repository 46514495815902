import $ from "jquery";
import initialPreLoader from "./libraries/preLoader/index";

// wave title


function processTextWrapper(selector) {
  var textWrapper = document.querySelector(selector);
  if (textWrapper) {
      var words = textWrapper.textContent.split(" ");
      textWrapper.textContent = "";
      for (var i = 0; i < words.length; i++) {
          textWrapper.innerHTML += "<span>" + words[i] + "</span>" + " ";
      }
  }
}

$(document).ready(function () {
  processTextWrapper("#waveh-text1");
  processTextWrapper("#waveh-text2");
  processTextWrapper("#waveh-text3");
  processTextWrapper("#waveh-text4");
  processTextWrapper("#waveh-text5");
  processTextWrapper("#waveh-text6");
});


// question box click
$(".question-list").click(function () {
  $(this).find(".question-hidden").slideToggle();
  $(this).toggleClass("background");
});

$(".question-list").click(function () {
  $(this).find(".question-close").toggleClass("active");
  $(this).find(".question-add").toggleClass("disable");
});

// number wave in servisce card
$(document).ready(function () {
  $(".wave-text").each(function () {
    var text = $(this).text();
    $(this).text("");
    for (var i = 0; i < text.length; i++) {
      $(this).append("<span>" + text[i] + "</span>");
    }
  });
});

function preloadImage(url, callback) {
  var img = new Image();
  img.onload = function() {
      callback();
  };
  img.src = url;
}




$(document).ready(function() {
  const progressElement = $("#countdown");
  const preCounter = $("#count");
  let displayedProgress = 140;
  progressElement.text(displayedProgress);

  const updateProgress = () => {
    if (displayedProgress > 0) {
        displayedProgress--;
        progressElement.text(displayedProgress);
    } else {
        clearInterval(interval); // Stop the countdown when it reaches 0
        preCounter.hide(); // Hide the preCounter
        initialPreLoader(); // Call initialPreLoader
  
        // Wait a bit longer than the delay in initialPreLoader to apply the transition
        setTimeout(() => {
            document.body.style.transition = 'background-color 1.5s ease';
        }, 3150); // Slightly longer than 3000ms in initialPreLoader
    }
  };
  const intervalDuration = 3000 / 140; // Approximately 28.57 ms
  let interval = setInterval(updateProgress, intervalDuration); // Start the countdown
});