import { TimelineMax, TweenMax, Expo } from "gsap";
import animateOnScroll from "../imageToContent";

class Revealer {
  constructor(el, options) {
    this.options = {
      angle: 0
    };
    Object.assign(this.options, options);

    this.DOM = {};
    this.DOM.el = el;
    this.DOM.inner = this.DOM.el.firstElementChild;

    this.DOM.inner.style.width = `calc(100vw * ${Math.abs(
      Math.cos((this.options.angle * Math.PI) / 180)
    )} + 100vh * ${Math.abs(Math.sin((this.options.angle * Math.PI) / 180))})`;
    this.DOM.inner.style.height = `calc(100vw * ${Math.abs(
      Math.sin((this.options.angle * Math.PI) / 180)
    )} + 100vh * ${Math.abs(Math.cos((this.options.angle * Math.PI) / 180))})`;
    this.DOM.el.style.transform = `rotate3d(0,0,1,${this.options.angle}deg)`;

    this.DOM.reverse = this.DOM.inner.querySelector(".contentv8__reverse");
    if (this.DOM.reverse) {
      TweenMax.set(this.DOM.reverse, { rotation: -1 * this.options.angle });
    }
  }
}

// Content elements
const content = {
  first: document.querySelector(".contentv8--first"),
  second: document.querySelector(".contentv8--second")
};

// First page's content.
const firstPageContent = {
  img: content.first.querySelector(".intro__img"),
  title: content.first.querySelector(".intro__title"),
  enter: content.first.querySelector(".intro__enter")
};

// Second page's content.
const secondPageContent = {
  menuLinks: content.second.querySelectorAll(".menu__link"),
  backCtrl: content.second.querySelector(".contentv8__back"),
  overlayElems: [...content.second.querySelectorAll(".overlay")]
};

// Angle of rotation
const angle = -60;

// overlays
const overlays = [];
const overlaysTotal = secondPageContent.overlayElems.length;
secondPageContent.overlayElems.forEach((overlay, i) =>
  overlays.push(
    new Revealer(overlay, { angle: i % 2 === 0 ? -1 * angle : angle })
  )
);

// Revealer element (first page)
const revealer = new Revealer(content.first, { angle: angle });

// Animate things: show revealer animation, animate first page elements out (optional) and animate second page elements in (optional)
const showNextPage = () => {
  // Pointer events related class
  content.first.classList.add("contentv8--hidden");

  const ease = Expo.easeInOut;
  const duration = 2;

  var pageToggleTimeline = new TimelineMax()
    // Animate first page elements (optional)
    .to(
      firstPageContent.img,
      duration,
      {
        ease: ease,
        x: -150,
        opacity: 0
      },
      0
    )
    .to(
      firstPageContent.enter,
      duration * 0.5,
      {
        ease: ease,
        opacity: 0
      },
      0
    )

    // "Unreveal effect" (inner moves to one direction and reverse moves to the opposite one)
    .to(
      revealer.DOM.inner,
      duration,
      {
        ease: ease,
        y: "-100%"
      },
      0
    )
    .to(
      revealer.DOM.reverse,
      duration,
      {
        ease: ease,
        y: "100%"
      },
      0
    )

    .set(
      secondPageContent.menuLinks[overlaysTotal],
      { x: angle > 0 ? "140%" : "-140%" },
      0
    )
    .to(
      secondPageContent.menuLinks[overlaysTotal],
      duration,
      {
        ease: Expo.easeOut,
        x: "0%"
      },
      0
    );

  // Animate overlays
  let t = 0;
  for (let i = 0; i <= overlaysTotal - 1; ++i) {
    t = 0.35 * i + 0.35;
    pageToggleTimeline
      .to(
        overlays[overlaysTotal - 1 - i].DOM.inner,
        duration,
        {
          ease: ease,
          y: "-100%"
        },
        t
      )
      .set(
        secondPageContent.menuLinks[overlaysTotal - 1 - i],
        { x: i % 2 === 0 ? "140%" : "-140%" },
        t
      )
      .to(
        secondPageContent.menuLinks[overlaysTotal - 1 - i],
        duration,
        {
          ease: Expo.easeOut,
          x: "0%"
        },
        t + 0.1
      );
  }
};

// Hover animation on the intro "enter" element
let enterHoverAnimationRunning = false;
const onEnterHoverFn = () => {
  if (enterHoverAnimationRunning) {
    return false;
  }
  enterHoverAnimationRunning = true;

  letters = firstPageContent.titleLetters.filter((_) => Math.random() < 0.5);

  new TimelineMax({ onComplete: () => (enterHoverAnimationRunning = false) })
    .staggerTo(
      letters,
      0.2,
      {
        ease: Quad.easeIn,
        y: "-100%",
        opacity: 0
      },
      0.04,
      0
    )
    .staggerTo(
      letters,
      0.6,
      {
        ease: Quint.easeOut,
        startAt: { y: "35%" },
        y: "0%",
        opacity: 1
      },
      0.04,
      0.2
    );
};
// firstPageContent.enter.addEventListener('mouseenter', onEnterHoverFn);

function initialPreLoader() {
  showNextPage();
  setTimeout(() => {
    var elements = document.getElementsByClassName("contentv8--second");
    var elements2 = document.getElementsByClassName("contentv8--first");

    // for(var i = 0; i < elements.length; i++){
    //    elements[i].style.display = 'none';

    // }
    // for(var i = 0; i < elements2.length; i++){
    //     elements2[i].style.display = 'none';

    //  }
    var elementsArray = Array.prototype.slice.call(elements);

    elementsArray.forEach(function (element) {
      element.style.transition = "opacity 0.5s ease-out";
      element.style.opacity = "0";
      element.remove();
    });

    var elementsArray2 = Array.prototype.slice.call(elements2);

    elementsArray2.forEach(function (element) {
      element.style.transition = "opacity 0.5s ease-out";
      element.style.opacity = "0";
      element.remove();
    });
    animateOnScroll();

    document.querySelector(".st-container").classList.add("main-bodyv2");
    document.querySelector(".demo-4").classList.add("overflow");
    document.querySelector("main").classList.add("main-display");
  }, 3000);
}

export default initialPreLoader;
